<template>
	<CRow>
		<CCol>
			<h4 class="my-4">
				General Info
			</h4>
			<CInput
				v-model.trim="$v.localProductName.$model"
				data-test-id="product-name"
				:is-valid="!$v.localProductName.$error && null"
				:invalid-feedback="nameErrors"
				label="Product name*"
				@input="(value) => $emit('update:productName', value)"
			/>
			<CInput
				v-model.trim="$v.localSlug.$model"
				data-test-id="product-slug"
				:is-valid="!$v.localSlug.$error && null"
				:invalid-feedback="slugErrors"
				label="Slug*"
				description="Allow letters, numbers, and ( - )"
				@focus="handleOnFocus"
				@input="(value) => $emit('update:slug', value)"
			/>
			<CRow>
				<CCol lg="6">
					<CInput
						data-test-id="product-sku"
						:value="sku"
						readonly
						label="SKU"
					/>
				</CCol>
				<CCol lg="6">
					<BaseDropDown
						data-test-id="product-brand"
						:value="brand"
						:searchable="true"
						:options="getBrandOptions"
						label="name"
						label-drop-down="Brand"
						@select="(value) => $emit('update:brand', value)"
					/>
				</CCol>
			</CRow>
			<CRow>
				<CCol lg="6">
					<CInput
						data-test-id="product-uid"
						:value="uid"
						readonly
						label="uid"
					/>
				</CCol>
			</CRow>
			<CRow>
				<CCol lg="6">
					<div class="form-group mt-2">
						<p class="label label-btn-group">
							Product status
						</p>
						<BaseButtonGroup
							v-model="$v.localProductStatus.$model"
							:list="productStatusGroups"
							@input="(value) => $emit('update:productStatus', value)"
						/>
					</div>
				</CCol>
				<CCol lg="6">
					<div class="form-group mt-2">
						<p class="label label-btn-group">
							Product type
						</p>
						<BaseButtonGroup
							v-model="$v.localProductType.$model"
							:list="productTypeGroups"
							@input="(value) => $emit('update:productType', value)"
						/>
					</div>
				</CCol>
			</CRow>
			<CRow>
				<CCol lg="6">
					<BaseInputDate
						:value="newProductUntil"
						label="Set as new product until"
						@input="(value) => $emit('update:newProductUntil', value)"
					>
						<template #append-content>
							<CIcon name="cil-calendar" />
						</template>
					</BaseInputDate>
				</CCol>
			</CRow>
			<hr class="mt-4">
		</CCol>
	</CRow>
</template>

<script>
import slugify from 'slugify';
import { mapActions, mapGetters } from 'vuex';
import { required, maxLength, helpers } from 'vuelidate/lib/validators';
import { PRODUCT_STATUS_OPTIONS, PRODUCT_TYPE_OPTIONS, PRODUCT_TYPES } from '../enums/products';
import slugRegex from '../regex/slug';

export default {
	name: 'ProductEditGeneralInfo',
	validations: {
		localSlug: {
			required,
			slugFormat: helpers.regex('slug', slugRegex),
		},
		localProductName: {
			required,
			maxLength: maxLength(255),
		},
		localProductStatus: {
			required,
		},
		localProductType: {
			required,
		},
	},
	props: {
		productName: {
			type: String,
			default: '',
		},
		slug: {
			type: String,
			default: '',
		},
		sku: {
			type: String,
			default: '',
		},
		brand: {
			type: Object,
			default: () => ({}),
		},
		productStatus: {
			type: String,
			default: '',
		},
		productType: {
			type: String,
			default: '',
		},
		newProductUntil: {
			type: Date,
			default: null,
		},
		isAllowFreebie: {
			type: Boolean,
			default: true,
		},
		uid: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			localSlug: '',
			localProductName: '',
			localProductStatus: '',
			localProductType: '',
		};
	},
	computed: {
		...mapGetters({
			getBrandOptions: 'brands/getBrandOptions',
		}),
		isFreebie() {
			return this.localProductType === PRODUCT_TYPES.FREEBIE;
		},
		productStatusGroups() {
			return PRODUCT_STATUS_OPTIONS
				.filter((product) => product.value) // Filter all option out
				.map((newProduct) => ({ label: newProduct.name, value: newProduct.value }));
		},

		productTypeGroups() {
			return PRODUCT_TYPE_OPTIONS
				.filter((type) => type.value)
				.map((type) => ({ label: type.name, value: type.value }));
		},
		nameErrors() {
			if (!this.$v.localProductName.required) {
				return 'Required Field';
			}

			if (!this.$v.localProductName.maxLength) {
				return 'Maximum name length is 255';
			}

			return null;
		},
		slugErrors() {
			if (!this.$v.localSlug.required) {
				return 'Required Field';
			}

			if (!this.$v.localSlug.slugFormat) {
				return 'Invalid slug format';
			}

			return null;
		},
	},
	watch: {
		localProductType(value) {
			if (value === PRODUCT_TYPES.FREEBIE) {
				this.$emit('update:isAllowFreebie', true);
			}
		},
	},
	async created() {
		await this.getBrands({ per_page: 'all' });
		this.localProductName = this.productName;
		this.localSlug = this.slug;
		this.localProductStatus = this.productStatus;
		this.localProductType = this.productType;
	},
	methods: {
		...mapActions({
			getBrands: 'brands/getBrands',
		}),
		handleOnFocus() {
			if (!this.localSlug) {
				const slug = slugify(this.productName, {
					lower: true,
					strict: true,
				});
				this.localSlug = slug;
				this.$emit('update:slug', slug);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	.label-btn-group {
		margin-bottom: rem(10);
	}

	.form-group-switch {
		display: flex;
		align-items: center;
		height: 100%;

		.toggle-label {
			margin-bottom: 0;
			min-width: rem(156);
		}

		.switch-custom {
			margin-right: rem(12);
		}

		> span {
			font-size: rem(14);
			color: $color-black-90;
		}
	}
</style>
