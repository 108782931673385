<template>
	<CRow>
		<CCol>
			<h4 class="my-4">
				Education
			</h4>
			<CRow>
				<CCol lg="6">
					<div class="form-group">
						<p class="label label-btn-group">
							Student price
						</p>
						<BaseInputNumber
							:value="priceStudent"
							:decimal-scale="2"
							data-test-id="product-student-price"
							prepend-text="฿"
							disabled
							@input="(value) => $emit('update:priceStudent', value)"
						/>
					</div>
				</CCol>
				<CCol lg="6">
					<CInput
						:value="appleProgramId"
						data-test-id="program-id"
						label="Apple Program ID"
						@input="(value) => $emit('update:appleProgramId', value)"
					/>
				</CCol>
			</CRow>
			<CRow>
				<CCol lg="12">
					<div class="form-group mt-2">
						<p class="label label-btn-group">
							Apple product type
						</p>
						<BaseButtonGroup
							:value="appleProductType"
							:list="productTypeGroups"
							@input="(value) => $emit('update:appleProductType', value)"
						/>
					</div>
				</CCol>
			</CRow>
			<hr class="mt-4">
		</CCol>
	</CRow>
</template>

<script>
import { APPLE_PRODUCT_TYPE_OPTIONS } from '../enums/products';

export default {
	name: 'ProductEditEducation',
	props: {
		priceStudent: {
			type: String,
			default: null,
		},
		appleProgramId: {
			type: String,
			default: null,
		},
		appleProductType: {
			type: String,
			default: 'accessory',
		},
	},
	computed: {
		productTypeGroups() {
			return APPLE_PRODUCT_TYPE_OPTIONS
				.filter((type) => type.value) // Filter all option out
				.map((type) => ({ label: type.name, value: type.value }));
		},
	},
};
</script>

<style lang="scss" scoped>
	.label-btn-group {
		margin-bottom: rem(10);
	}

	.form-group-switch {
		display: flex;
		align-items: center;
		height: 100%;

		.toggle-label {
			margin-bottom: 0;
			min-width: rem(156);
		}

		.switch-custom {
			margin-right: rem(12);
		}

		> span {
			font-size: rem(14);
			color: $color-black-90;
		}
	}
</style>
