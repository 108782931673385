<template>
	<CRow>
		<CCol>
			<h4 class="my-4">
				Label
			</h4>
			<ProductEditLabelList
				:type="PRODUCT_LABEL_TYPES.primary"
				:list="primaryLabels"
				title="Primary label"
				description="Primary label will be displayed right over the product cover image at the product list and  the right side on product detail page below the product price."
				@onAdd="openProductPrimaryModal"
				@onDelete="handleDelete"
			/>
			<ProductEditLabelList
				:type="PRODUCT_LABEL_TYPES.secondary"
				:list="secondaryLabels"
				:disabled="disableAddSecondaryLabelButton"
				title="Secondary label"
				description="Secondary label will be displayed below the product cover image at the product list and below the product image gallery on the product detail page. Allows only 1 bottom label for each product."
				@onAdd="openProductSecondaryModal"
				@onDelete="handleDelete"
			/>
			<ProductEditLabelList
				:type="PRODUCT_LABEL_TYPES.frame"
				:list="frameLabels"
				:disabled="disableAddFrameLabelButton"
				title="Frame label"
				description="Frame label will display as a overlay in front of produce image at product list and the first image of product image on product detail page."
				@onAdd="openProductFrameModal"
				@onDelete="handleDelete"
			/>
			<hr class="mt-2">
		</CCol>

		<ModalProductLabel
			ref="modal-primary-label"
			@onSubmit="handleSubmit"
		/>

		<ModalProductLabel
			ref="modal-secondary-label"
			single-select
			@onSubmit="handleSubmit"
		/>

		<ModalProductLabel
			ref="modal-frame-label"
			single-select
			@onSubmit="handleSubmit"
		/>
	</CRow>
</template>

<script>
import ModalProductLabel from '@/components/ModalProductLabel.vue';
import ProductEditLabelList from '@/components/ProductEditLabelList.vue';
import { PRODUCT_LABEL_TYPES } from '../enums/productLabels';

export default {
	name: 'ProductEditLabel',

	components: {
		ModalProductLabel,
		ProductEditLabelList,
	},
	props: {
		primaryLabels: {
			type: Array,
			default: () => [],
		},
		secondaryLabels: {
			type: Array,
			default: () => [],
		},
		frameLabels: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		PRODUCT_LABEL_TYPES() {
			return PRODUCT_LABEL_TYPES;
		},
		disableAddSecondaryLabelButton() {
			return this.secondaryLabels && this.secondaryLabels.length > 0;
		},
		disableAddFrameLabelButton() {
			return this.frameLabels && this.frameLabels.length > 0;
		},
	},
	methods: {
		handleSubmit({ labels, type }) {
			// Filter existing label out
			const newLabels = labels.filter((image) => {
				return !this[`${type}Labels`].some((oldLabel) => oldLabel.id === image.id);
			});

			const allLabels = [
				...this[`${type}Labels`],
				...newLabels,
			];

			this.emitInputValue(allLabels, type);
		},
		handleDelete({ id, type }) {
			const allLabels = this[`${type}Labels`].filter((label) => label.id !== id);
			this.emitInputValue(allLabels, type);
		},
		openProductPrimaryModal(type) {
			this.$refs['modal-primary-label'].open(type);
		},
		openProductSecondaryModal(type) {
			this.$refs['modal-secondary-label'].open(type);
		},
		openProductFrameModal(type) {
			this.$refs['modal-frame-label'].open(type);
		},
		emitInputValue(allLabels, type) {
			this.$emit(`update:${type}Labels`, allLabels);
		},
	},
};
</script>
